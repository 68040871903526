import React from 'react';
import { ImFacebook2, ImRss2 } from 'react-icons/im';
import uiconf from '../../config/config';
import developedWithYoutube from '../../assets/developed-with-youtube.png';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.flex}>
        <div className={styles.info}>
          <p>{uiconf.footer.company}</p>
          <p>{uiconf.footer.address}</p>
          <p>Phone: {uiconf.footer.phone}</p>
          <p>Email: {uiconf.footer.email}</p>
        </div>
        <div className={styles.social}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/Iterator"
          >
            <ImFacebook2 />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/feeds/videos.xml?channel_id=UCT9Op5RQ6nj-jhUYFRduenw"
          >
            <ImRss2 />
          </a>
        </div>
      </div>

      <div className={styles.column}>
        <div className={styles.copyright}>
          Copyright © 2023{' '}
          <a href="https://tv.iterator.fi/">
            {uiconf.footer.copyright.copyright}
          </a>
          , Content by{' '}
          <a href="https://www.youtube.com/@TaivalOutdoors">
            {uiconf.footer.copyright.content}
          </a>{' '}
        </div>
        <div className={styles.developedWithYoutube}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/@draisinerally9585"
          >
            <img src={developedWithYoutube} alt="YouTube" />
          </a>
        </div>
        <div className={styles.legal}>
          <a href="https://www.youtube.com/t/terms">
            YouTube's Terms of Service |
          </a>
          <a href="https://tv.iterator.fi/en/terms">Terms of Service |</a>
          <a href="https://tv.iterator.fi/en/privacy">Privacy Policy</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
