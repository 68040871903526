import { useState, useEffect } from 'react';

export const useFetchData = (url) => {
  const [data, setData] = useState(null);
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [dataError, setDataError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(url);
        const data = await res.json();

        setData(data);
        setDataIsLoading(false);
      } catch (err) {
        setDataError(err.message);
        setDataIsLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { data, dataIsLoading, dataError };
};
