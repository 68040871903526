import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import styles from './Navigation.module.css';

const languageOptions = [
  { value: 'finnish', label: 'Finnish' },
  { value: 'english', label: 'English' },
  { value: 'spanish', label: 'Spanish' },
];

const Navigation = ({
  brandText,
  seriesLink,
  infoLink,
  searchPlaceholder,
  language,
}) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [languageOption, setLanguageOption] = useState(() => {
    switch (language) {
      case 'en':
        return languageOptions.find((option) => {
          return option.value === 'english';
        });
      case 'fi':
        return languageOptions.find((option) => {
          return option.value === 'finnish';
        });
      default:
        return null;
    }
  });

  const filterResults = (event) => {
    const search = event.target.value;
    setSearch(search);
  };

  const submitSearch = (event) => {
    if (event.key === 'Enter') {
      if (!search) {
        navigate(`/${language}`);
      } else {
        navigate(`/${language}/search?query=${encodeURIComponent(search)}`);
      }
    }
  };

  const handleLanguageChange = (selectedOption) => {
    setLanguageOption(selectedOption);

    if (selectedOption.value === 'finnish') {
      navigate('fi');
    } else if (selectedOption.value === 'english') {
      navigate('en');
    }

    window.location.reload();
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbar_brand_wrapper}>
        <a className={styles.navbar_brand} href={`/${language}`}>
          {brandText}
        </a>
      </div>

      <div className={styles.navbar_links_wrapper}>
        <ul className={styles.navbar_item_wrapper}>
          <li className={styles.navbar_item}>
            <Link to={`${language}/series`}>{seriesLink}</Link>
          </li>
          <li className={styles.navbar_item}>
            <Link to={`${language}/info`}>{infoLink}</Link>
          </li>
        </ul>

        <div className={styles.navbar_search_wrapper}>
          <input
            type="text"
            value={search}
            onChange={filterResults}
            onKeyDown={submitSearch}
            placeholder={searchPlaceholder}
          />
        </div>

        <div className={styles.language}>
          <Select
            defaultValue={languageOption}
            onChange={handleLanguageChange}
            options={languageOptions}
          />
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
