import styles from './InfoPage.module.css';

const InfoPage = ({ headerText, descriptionText }) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <h2>{headerText}</h2>
      </div>
      <div className={styles.bodyWrapper}>
        <p>{descriptionText}</p>
      </div>
    </div>
  );
};

export default InfoPage;
