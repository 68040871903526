import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import LanguageRouter from './components/LanguageRouter';

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const clientSpecifiedLanguage = 'sv';
  const supportedLanguages = useMemo(() => ['fi', 'en'], []);

  /**
   * Get language from the url string
   * @type {*}
   * */
  const languageInURL = location.pathname.split('/')[1];

  /**
   * Get language information if its available in cookies
   * @type {*}
   * */
  const languageInCookies = document.cookie
    .split('; ')
    .find((cookie) => cookie.startsWith('language='))
    ?.split('=')[1];

  useEffect(() => {
    const checkSupportedLanguages = () => {
      if (supportedLanguages.includes(languageInCookies)) {
        navigate(languageInCookies);
      } else {
        const browserPreferredLanguages = navigator.languages;
        const browserPreferredLanguage = supportedLanguages.find(
          (supportedLanguage) => {
            const matchedLanguage = browserPreferredLanguages.find(
              (preferredLanguage) => {
                // Check if the preferred language starts with the supported language
                return preferredLanguage.startsWith(supportedLanguage);
              }
            );

            if (matchedLanguage) {
              // Parse off the region part from the matched language if it exists
              const languageParts = matchedLanguage.split('-');
              const languageWithoutRegion = languageParts[0];

              return languageWithoutRegion;
            }

            return null;
          }
        );

        if (browserPreferredLanguage) {
          navigate(browserPreferredLanguage);
        } else {
          navigate(clientSpecifiedLanguage);
        }
      }
    };

    /**
     * Check if the language is defined in the url string
     * @param {string}
     */
    if (!languageInURL) {
      checkSupportedLanguages();
    }
  }, [navigate, languageInURL, languageInCookies, supportedLanguages]);

  if (supportedLanguages.includes(languageInURL)) {
    return <LanguageRouter language={languageInURL} />;
  } else {
    return (
      <code>
        <b>404</b> language {languageInURL} is not supported
      </code>
    );
  }
}
