import { Route, Routes } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Navigation from '../Navigation/Navigation';
import config from '../../config/config';
import CategoryPage from '../../views/CategoryPage';
import HighlightedSeriePage from '../../views/HighlightedSeriePage';
import InfoPage from '../../views/InfoPage';
import MediaPlayerPage from '../../views/MediaPlayerPage';
import SearchResultsPage from '../../views/SearchResultsPage';
import SeriesPage from '../../views/SeriesPage';
import Category from '../Category';
import Highlighted from '../Highlighted';
import HighlightedSeries from '../HighlightedSeries';

export default function LanguageRouter({ language }) {
  return (
    <div>
      <Navigation
        brandText={config.brand}
        seriesLink={language === 'fi' ? 'Ohjelmasarjat' : 'Series'}
        infoLink="Info"
        searchPlaceholder={language === 'fi' ? 'Etsi' : 'Search'}
        language={language}
      />

      <Routes>
        <Route
          path={`${language}`}
          element={
            <>
              <Highlighted
                url={`https://tv.iterator.fi/${language}/api/v1/video/highlighted`}
                language={language}
              />
              <Category
                headerText={language === 'fi' ? 'Uusimmat' : 'Latest'}
                path="latest"
                url={`https://tv.iterator.fi/${language}/api/v1/video/latest`}
                language={language}
              />
              <Category
                headerText={language === 'fi' ? 'Katsotuimmat' : 'Most Watched'}
                path="popular"
                url={`https://tv.iterator.fi/${language}/api/v1/video/popular`}
                language={language}
              />
              <HighlightedSeries
                headerText={language === 'fi' ? 'Sarjat' : 'Series'}
                path="series"
                url={`https://tv.iterator.fi/${language}/api/v1/category/list`}
                language={language}
              />
            </>
          }
        />
        {/* Define routes for specific language */}
        <Route
          path={`${language}/info`}
          element={
            <InfoPage
              headerText="Info"
              descriptionText={config.info.description}
            />
          }
        />
        <Route
          path={`${language}/latest`}
          element={
            <CategoryPage
              headerText={language === 'fi' ? 'Uusimmat' : 'Latest'}
              url={`https://tv.iterator.fi/${language}/api/v1/video/latest`}
              language={language}
            />
          }
        />
        <Route
          path={`${language}/popular`}
          element={
            <CategoryPage
              headerText={language === 'fi' ? 'Katsotuimmat' : 'Most Watched'}
              url={`https://tv.iterator.fi/${language}/api/v1/video/popular`}
              language={language}
            />
          }
        />
        <Route
          path={`${language}/series`}
          element={
            <SeriesPage
              headerText={language === 'fi' ? 'Sarjat' : 'Series'}
              url={`https://tv.iterator.fi/${language}/api/v1/category/list`}
              language={language}
            />
          }
        />
        <Route
          path={`${language}/search`}
          element={
            <SearchResultsPage
              headerText={language === 'fi' ? 'Hakutulokset' : 'Search Results'}
              url={`https://tv.iterator.fi/${language}/api/v1/video/search`}
              language={language}
            />
          }
        />
        <Route
          path={`${language}/video/:id`}
          element={
            <MediaPlayerPage
              url={`https://tv.iterator.fi/${language}/api/v1/video/get/`}
              language={language}
            />
          }
        />
        <Route
          path={`${language}/serie/:id`}
          element={
            <HighlightedSeriePage
              headerText={
                language === 'fi' ? 'Ohjelmasarja' : 'Highlighted Serie'
              }
              url={`https://tv.iterator.fi/${language}/api/v1/video/category/`}
              language={language}
            />
          }
        />
      </Routes>

      <Footer />
    </div>
  );
}
