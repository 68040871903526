import { useNavigate } from 'react-router-dom';
import { useFetchData } from '../../hooks/useFetchData';
import { openVideo } from '../../utils/video';
import styles from './Category.module.css';

const Category = ({ headerText, path, url, language }) => {
  const navigate = useNavigate();

  const { data, dataIsLoading, dataError } = useFetchData(url);

  if (dataIsLoading) {
    return <div>Loading ...</div>;
  }

  if (dataError) {
    return <div>Error: {dataError}</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <h2>{headerText}</h2>
      </div>

      <div className={styles.videoWrapper}>
        {data.items.slice(0, 3).map((video) => {
          return (
            <div
              className={styles.video}
              key={video.id}
              onClick={() => openVideo(navigate, language, video)}
            >
              <div className={styles.imageWrapper}>
                <img src={video.thumbnailLarge} alt={video.title} />
                <span>{video.title}</span>
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.showMore}>
        <button onClick={() => navigate(path)}>More&nbsp;»</button>
      </div>
    </div>
  );
};

export default Category;
