const config = Object.freeze({
  brand: 'Iterator Web TV Demo',

  footer: {
    company: 'Iterator Oy',
    address: 'Kampinkuja 2, 00100 Helsinki, Finland',
    phone: '+358 40 720 3670',
    email: 'webtv@iterator.fi',

    copyright: {
      copyright: 'Iterator Oy',
      content: 'Taival Outdoors',
    },
  },

  info: {
    description:
      "Iterator's Web TV displays the videos from your Youtube channel on your own dedicated Web TV.",
  },
});

export default config;
