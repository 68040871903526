import { useNavigate } from 'react-router-dom';
import { useFetchData } from '../../hooks/useFetchData';
import { openVideo } from '../../utils/video';
import styles from './Highlighted.module.css';

const Highlighted = ({ url, language }) => {
  const navigate = useNavigate();

  const { data, dataIsLoading, dataError } = useFetchData(url);

  if (dataIsLoading) {
    return <div>Loading ...</div>;
  }

  if (dataError) {
    return <div>Error: {dataError}</div>;
  }

  const slicedItems = data.items.slice(0, 3);

  return (
    <div className={styles.container}>
      <div className={styles.videoWrapper}>
        {slicedItems.map((video, index) => {
          if (index === 0) {
            return (
              <div
                key={video.id}
                className={styles.largeItem}
                onClick={() => openVideo(navigate, language, video)}
              >
                <img src={video.thumbnailLarge} alt={video.title} />
                <span>{video.title}</span>
              </div>
            );
          } else if (index === 1) {
            return (
              <div key={video.id} className={styles.flex}>
                <div
                  className={styles.regularItem}
                  onClick={() => openVideo(navigate, language, slicedItems[1])}
                >
                  <img
                    src={slicedItems[1].thumbnail}
                    alt={slicedItems[1].title}
                  />
                  <span>{slicedItems[1].title}</span>
                </div>
                <div
                  className={styles.regularItem}
                  onClick={() => openVideo(navigate, language, slicedItems[2])}
                >
                  <img
                    src={slicedItems[2].thumbnail}
                    alt={slicedItems[2].title}
                  />
                  <span>{slicedItems[2].title}</span>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default Highlighted;
