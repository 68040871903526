import { useLocation, useNavigate } from 'react-router-dom';
import { useFetchData } from '../../hooks/useFetchData';
import { openVideo } from '../../utils/video';
import styles from './HighlightedSeriePage.module.css';

const HighlightedSeriePage = ({ headerText, url, language }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const serieId = location.pathname.split('/').pop();

  const { data, dataIsLoading, dataError } = useFetchData(`${url}/${serieId}`);

  if (dataIsLoading) {
    return <div>Loading...</div>;
  }

  if (dataError) {
    return <div>Error: {dataError}</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <h2>{headerText}</h2>
      </div>

      <div className={styles.videoWrapper}>
        {data.items.map((video) => (
          <div
            key={video.id}
            className={styles.video}
            onClick={() => openVideo(navigate, language, video)}
          >
            <div className={styles.imageWrapper}>
              <img src={video.thumbnail} alt={video.title} />
              <span>{video.title}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HighlightedSeriePage;
