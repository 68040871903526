import { useNavigate } from 'react-router-dom';
import { useFetchData } from '../../hooks/useFetchData';
import styles from './HighlightedSeries.module.css';

const HighlightedSeries = ({ headerText, path, url, language }) => {
  const navigate = useNavigate();

  const { data, dataIsLoading, dataError } = useFetchData(url);

  if (dataIsLoading) {
    return <div>Loading ...</div>;
  }

  if (dataError) {
    return <div>Error: {dataError}</div>;
  }

  const slicedItems = data.items.slice(0, 3);

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <h2>{headerText}</h2>
      </div>

      <div className={styles.seriesWrapper}>
        {slicedItems.map((serie) => {
          return (
            <div
              key={serie.id}
              className={styles.serie}
              onClick={() => navigate(`/${language}/serie/${serie.id}`)}
            >
              <div className={styles.imageWrapper}>
                <img src={serie.thumbnail} alt={serie.title} />
                <span>{serie.title}</span>
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.showMore}>
        <button onClick={() => navigate(path)}>More&nbsp;»</button>
      </div>
    </div>
  );
};

export default HighlightedSeries;
