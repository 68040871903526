import { useLocation, useNavigate } from 'react-router-dom';
import { useFetchData } from '../../hooks/useFetchData';
import { openVideo } from '../../utils/video';
import styles from './SearchResultsPage.module.css';

const SearchResultsPage = ({ headerText, url, language }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('query');
  const searchEndpoint = `${url}/${encodeURIComponent(searchQuery)}`;

  const { data, dataIsLoading, dataError } = useFetchData(searchEndpoint);
  const searchedVideos = data?.items || [];

  if (dataIsLoading) {
    return <div>Loading...</div>;
  }

  if (dataError) {
    return <div>Error: {dataError}</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <h2>{headerText}</h2>
      </div>
      {searchedVideos.length === 0 ? (
        <div>No results found for: ${searchQuery}</div>
      ) : (
        <div className={styles.videoWrapper}>
          {searchedVideos.map((video) => (
            <div
              key={video.id}
              className={styles.video}
              onClick={() => openVideo(navigate, language, video)}
            >
              <div className={styles.imageWrapper}>
                <img src={video.thumbnailLarge} alt={video.title} />
                <span>{video.title}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchResultsPage;
