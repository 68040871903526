import { Helmet } from 'react-helmet-async';
import { ImEnvelop, ImFacebook2, ImTwitter } from 'react-icons/im';
import { useParams } from 'react-router-dom';
import { useFetchData } from '../../hooks/useFetchData';
import styles from './MediaPlayerPage.module.css';
import { useEffect, useState } from 'react';

const MediaPlayerPage = ({ url, language }) => {
  const params = useParams();
  const {
    data: video,
    dataIsLoading,
    dataError,
  } = useFetchData(`${url}${params.id}`);

  const [ogUrl, setOgUrl] = useState('');

  useEffect(() => {
    setOgUrl(window.location.href);
  }, []);

  if (dataIsLoading) {
    return <div>Loading...</div>;
  }

  if (dataError) {
    return <div>Error: {dataError}</div>;
  }

  if (!video) {
    return <div>Invalid video url</div>;
  }

  const formatDescription = video.description
    .split('\n')
    .map((section, index) => {
      return <p key={index}>{section}</p>;
    });

  const formatDate = new Date(video.published * 1000).toLocaleDateString();

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{video.title}</title>
        <meta name="description" content={video.description} />
        {/* Facebook Meta Tags */}
        <meta property="og:url" content={ogUrl} />{' '}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={video.title} />
        <meta property="og:description" content={video.description} />
        <meta property="og:image" content={video.thumbnailLarge} />
        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="https://tv-dev.iterator.fi" />
        <meta property="twitter:url" content={ogUrl} />
        <meta name="twitter:title" content={video.title} />
        <meta name="twitter:description" content={video.description} />
        <meta name="twitter:image" content={video.thumbnailLarge} />
      </Helmet>

      <div className={styles.container}>
        <iframe
          className={styles.iframe}
          title={video.title}
          src={`https://www.youtube.com/embed/${video.id}`}
          frameborder="0"
          allowfullscreen
        ></iframe>

        <div className={styles.videoDetails}>
          <h2>{video.title}</h2>
          <p>{formatDate}</p>
          <p>{formatDescription}</p>
        </div>

        <div className={styles.actions}>
          <ul>
            <li class="facebook">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=https://tv.iterator.fi/en/video/${video.id}`}
              >
                <ImFacebook2 />
              </a>
            </li>
            <li class="twitter">
              <a
                href={`https://twitter.com/intent/tweet?text=https://tv.iterator.fi/en/video/${video.id}`}
              >
                <ImTwitter />
              </a>
            </li>
            <li class="email">
              <a
                href={`mailto:?subject=${video.title}&body=https://tv.iterator.fi/en/video/${video.id}`}
              >
                <ImEnvelop />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MediaPlayerPage;
